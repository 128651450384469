@charset "UTF-8";

// common.scssにimport
/**=================================

ここから業務用販売(_wholesale.scss)

=================================**/

/************************************

#mainimage

************************************/
#wholesale {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#wholesale .sec

************************************/
#wholesale .sec {
  margin-top: -110px;
  padding-top: 110px;
  @include media-tab {
    margin-top: 0;
    padding-top: 0;
  }
}
/************************************

#wholesale_info

************************************/
#wholesale_info {
  margin: 0 0 110px;

  @include media-tab {
    margin: 0 0 40px;
  }
  .inpagenavi {
    li {
      display: inline-block;
      margin: 0 10px;
      @include media-tab {
        display: block;
        width: 100%;
        max-width: 400px;
        margin: 5px auto;
        font-size: 90%;
      }
      a {
        display: block;
        border: 1px solid $main-color;
        padding: 7px 40px 7px 20px;
        border-radius: 2em;
        position: relative;
        @include media-tab {
          padding: 7px 0px;
        }
        &:hover {
          background-color: $main-color;
          color: #fff;
          opacity: 1;
        }
        &::before {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          right: 12px;
          margin: auto;
          font-family: "FontAwesome";
          content: "\f107";
        }
      }
    }
  }
}
/************************************

#wholesale .lead

************************************/
#wholesale .lead {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 700px;
    @include media-tab {
      max-width: 90%;
    }
  }
  h2 {
    position: relative;
    display: inline-block;
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
    margin: 0 0 50px;
    padding: 20px 50px;
    @include media-tab {
      font-size: 2.1rem;
      padding: 15px 30px;
      margin: 0 0 30px;
    }
    @include media-sp {
      font-size: 1.8rem;
      padding: 15px 0;
      display: block;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0px;
      width: 50px;
      height: 50px;
      border-top: 1px solid $main-color;
      border-left: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
    &::after {
      position: absolute;
      bottom: 0;
      right: 0px;
      width: 50px;
      height: 50px;
      border-bottom: 1px solid $main-color;
      border-right: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
  }
  p {
    margin: 0 0 50px;
    line-height: 2;
    text-align: left;
    @include media-tab {
      font-size: 1.5rem;
      margin: 0 0 25px;
    }
  }
}

/************************************

#sec1 .kodawari

************************************/
#wholesale h2.sen {
  font-size: 2.4rem;
  letter-spacing: 0.08em;
  display: inline-block;
  position: relative;
  margin: 0 auto 70px;
  @include media-tab {
    font-size: 2.1rem;
    letter-spacing: 0.03em;
    margin: 0 auto 50px;
  }
  &::before {
    position: absolute;
    bottom: -10px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100px;
    height: 1px;
    background-color: $main-color;
    content: "";
  }
}
#wholesale .kodawari {
  margin: 0 0 80px;
  padding: 80px 3%;
  background-color: $back-color1;
  @include media-tab {
    margin: 0 0 40px;
    padding: 40px 3%;
  }

  .list {
    @include media-tab {
      //max-width: 600px;
      margin: 0 auto;
    }
    article {
      width: 21%;
      margin: 0 2%;
      @include media-tab {
        width: 46%;
        margin: 0 2% 2%;
      }
      @include media-sp {
        width: 90%;
        margin: 0 auto 30px;
      }
      .image img {
        border-radius: 50%;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        @include media-tab {
          max-width: 70%;
          margin: 0 auto;
        }
      }
      .text {
        position: relative;
        padding: 40px 0 0;
        @include media-tab {
          padding: 30px 0 0;
        }
        .no {
          position: absolute;
          top: -30px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          color: #c4bdab;
          font-size: 7rem;
          line-height: 1;
          @include media-tab {
            font-size: 5rem;
          }
        }
        h3 {
          font-family: $font-family-go;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.7;
          margin: 0 0 10px;
          @include media-tab {
            text-align: left;
          }
        }
        p {
          font-size: 1.5rem;
          text-align: left;
        }
      }
    }
  }
}
/************************************

#sec1 #partner

************************************/

#sec1 #partner {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 800px;
  }
  .list {
    article {
      width: 46%;
      margin: 0 2% 30px;
      text-align: left;
      @include media-tab {
        width: 90%;
        margin: 0 auto 20px;
      }
      h3 {
        font-family: $font-family-go;
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 1.7;
        margin: 0 0 10px;
      }
      p {
        font-size: 1.5rem;
        text-align: left;
      }
    }
  }
}

/************************************

#sec2 #flow

************************************/
#sec2 #flow {
  margin: 0 0 80px;
  padding: 80px 5%;
  background-color: $back-color1;
  @include media-tab {
    margin: 0 0 40px;
    padding: 40px 5%;
  }
  .chart {
    text-align: left;
    max-width: 860px;
    margin: 0 auto;
  }
  dl {
    margin: 0 0 15px;
    @include clearfix;
    @include media-sp {
      padding: 0 0 55px;
      background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/flow_yaji@2x.png);
      background-size: 10px;
      background-position: center bottom;
      background-repeat: no-repeat;
    }
    dt {
      float: left;
      width: 35%;
      border: 1px solid $main-color;
      background-color: #fff;
      padding: 10px;
      position: relative;
      margin: 0 0 47px;
      @include media-sp {
        float: none;
        width: 100%;
        margin: 0 0;
      }
      &::before {
        position: absolute;
        bottom: -47px;
        left: 0px;
        right: 0px;
        margin: auto;
        width: 10px;
        height: 47px;
        background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/flow_yaji@2x.png);
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
        @include media-sp {
          display: none;
        }
      }
      @include clearfix;
      .no {
        background-color: $main-color;
        color: #fff;
        font-weight: bold;
        text-align: center;
        width: 35px;
        font-size: 20px;
        line-height: 1;
        padding: 7px 0;
        float: left;
        @include media-tab {
          width: 24px;
          font-size: 16px;
          padding: 4px 0;
        }
      }
      h3 {
        margin-left: 45px;
        font-family: $font-family-go;
        font-weight: bold;
        font-size: 1.7rem;
        line-height: 2;
        @include media-tab {
          line-height: 1.4;
          margin-left: 30px;
          font-size: 1.6rem;
        }
      }
    }
    dd {
      float: right;
      width: 60%;
      padding: 10px 0 0;
      @include media-tab {
        font-size: 1.5rem;
      }
      @include media-sp {
        float: none;
        width: 100%;
      }
      .bt a {
        background-color: $main-color;
        color: #fff;
        font-family: $font-family-go;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1;
        padding: 10px 30px;
        border-radius: 2em;
        margin: 10px 0 0;
        &::before {
          display: none;
        }
      }
    }
    &:last-child {
      margin: 0;
      padding: 0;
      background-image: none;
      dt {
        margin: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}
/************************************

#sec2 #case

************************************/
#sec2 #case {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 900px;
    @include media-tab {
      max-width: 90%;
    }
    @include media-sp {
      max-width: 100%;
    }
  }
  article {
    margin: 0 0 40px;
    text-align: left;
    @include clearfix;
    &:last-child {
      margin: 0;
    }
    .image {
      float: right;
      width: 32%;
      @include media-tab {
        float: none;
        width: 100%;
        max-width: 300px;
        margin: 0 auto 20px;
      }
    }
    .text {
      float: left;
      width: 63%;
      @include media-tab {
        float: none;
        width: 100%;
      }
    }
    .no {
      font-size: 7rem;
      line-height: 1;
      color: #c4bdab;
      float: left;
      width: 150px;
      height: 100px;
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 60%;
      margin: 0 0 15px;
      @include media-tab {
        font-size: 5rem;
        width: 100px;
        height: 66px;
      }
    }
    &.rakuno .no {
      background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/oem_ill1@2x.jpg);
    }
    &.cafe .no {
      background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/oem_ill2@2x.jpg);
    }
    h3 {
      margin-left: 180px;
      font-size: 2.4rem;
      padding: 15px 0 0;
      @include media-tab {
        margin-left: 110px;
        font-size: 2.1rem;
      }
    }
    dl {
      clear: both;
      margin: 0 0 20px;
      @include clearfix;
      @include media-sp {
        margin: 0 3% 20px;
      }
      dt {
        float: left;
        width: 120px;
        font-family: $font-family-go;
        font-weight: bold;
        @include media-sp {
          float: none;
          width: 100%;
        }
      }
      dd {
        margin-left: 120px;
        border-left: 1px solid $main-color;
        padding: 0 0 0 20px;
        @include media-tab {
          font-size: 1.5rem;
        }
        @include media-sp {
          margin: 10px 0 0;
          border: none;
          padding: 0;
        }
      }
    }
  }
}
/************************************

#sec3 #kodawari2

************************************/
#sec3 #kodawari2 {
  .list article h3 {
    text-align: center;
  }
  .bt {
    margin: 30px 0 0;
  }
}
