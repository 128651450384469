@charset "UTF-8";

// common.scssにimport
/**=================================

ここからトップページ(_top.scss)

=================================**/

/************************************

#mainimage

************************************/
#top {
  #mainimage {
    margin: 0 auto 30px;
    @include media-tab {
    }
    .mainslide_waku {
      width: 100%;
      height: calc(100vh - 110px);
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-tab {
        height: 45vw;
      }
      @include media-sp {
        height: 95vw;
      }
      .copy {
        position: absolute;
        width: 86%;
        bottom: 7%;
        left: 7%;
        color: #fff;
        text-align: left;
        @include media-tab {
          top: 30%;
          bottom: auto;
        }
        @include media-sp {
          top: auto;
          bottom: 7%;
        }
        h2 {
          font-size: 4.8rem;
          @include media-tab {
            font-size: 2.6rem;
          }
          @include media-sp {
            font-size: 2.1rem;
          }
        }
        p {
          font-size: 1.6rem;
          @include media-tab {
            font-size: 1.4rem;
          }
          @include media-sp {
            font-size: 1.1rem;
          }
        }
      }
      &:nth-child(1) {
        background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage1.jpg");
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage1@2x.jpg");
        }
        @include media-sp {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp1@2x.jpg");
        }
      }
      &:nth-child(2) {
        background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage2.jpg");
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage2@2x.jpg");
        }
        @include media-sp {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp2@2x.jpg");
        }
      }
      &:nth-child(3) {
        background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage3.jpg");
        @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage3@2x.jpg");
        }
        @include media-sp {
          background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp3@2x.jpg");
        }
      }
    }
  }
}
/************************************

#information

************************************/
#top {
  #information {
    width: 100%;
    margin: 0 auto 30px;
    padding: 50px 0;
    background-color: $back-color1;
    @include media-tab {
      padding: 30px 0;
    }
    ul {
      display: block; /* before Android4.3 iOS6:Safari */
      display: -ms-flexbox; /*IE10*/
      display: -webkit-flex; /* Safari */
      display: flex;
      -webkit-flex-wrap: wrap; /* Safari */
      flex-wrap: wrap;
      margin: 0 0 30px;
      @include media-tab {
        margin: 0 0 20px;
      }
      li {
        width: 46%;
        margin: 10px 2%;
        text-align: left;
        @include clearfix;
        @include media-tab {
          width: 100%;
          margin: 10px 0;
        }
        a {
          display: block;
        }
      }
    }
    .image {
      float: left;
      width: 25%;
      @include media-tab {
        width: 35%;
      }
    }
    .text {
      float: right;
      width: 70%;
      @include media-tab {
        width: 60%;
      }
      .date {
        font-size: 1.1rem;
      }
      h3 {
        font-size: 1.5rem;
        line-height: 1.6;
        @include media-tab {
          font-size: 1.3rem;
        }
      }
    }
  }
}
/************************************

#about

************************************/
#top {
  #about {
    width: 100%;
    margin: 0 auto 50px;
    background-image: url("/wp/wp-content/themes/itsuki02/images/about_img.jpg");
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 14vw 0 0;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/images/about_img@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/images/about_img-sp@2x.jpg");
      padding: 30vw 0 0;
    }
    .text {
      margin: 0 auto;
      padding: 70px 0 0;
      background-color: #fff;
      width: 330px;
      @include media-tab {
        padding: 40px 0 0;
        width: 250px;
      }
      @include media-sp {
        width: 200px;
      }
      h2 {
        margin: 0 auto 70px;
        width: 160px;
        @include media-tab {
          width: 120px;
          margin: 0 auto 40px;
        }
        a {
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-decoration: none;
          background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
          background-repeat: no-repeat;
          background-position: left top;
          background-size: contain;
          display: block;
          height: 0;
          width: 100%;
          padding-top: 50%;
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
            background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
          }
        }
      }
      p {
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        text-align: left;
        line-height: 2.2;
        display: inline-block;
        margin: 0 0 20px;
        @include media-tab {
          font-size: 1.4rem;
        }
      }
    }
  }
}
/************************************

#lineup

************************************/
#top {
  #lineup {
    width: 100%;
    margin: 0 auto 30px;
    padding: 50px 0;
    background-color: $back-color1;
    @include media-tab {
      padding: 30px 0;
    }
    ul {
      margin: 0 0 30px;
      @include clearfix;
      @include media-tab {
        margin: 0 0 20px;
      }
      .slick-list {
        @include clearfix;
      }
      li {
        text-align: left;
        padding: 0 30px;
        @include media-tab {
          padding: 0 10px;
        }
        a {
          display: block;
        }
      }
    }

    .text {
      margin: 1em 0 0;
      h3 {
        font-size: 1.5rem;
        line-height: 1.6;
        margin: 0 0 1em;
        @include media-tab {
          font-size: 1.3rem;
        }
      }
      p {
        font-size: 1.4rem;
        @include media-tab {
          font-size: 1.1rem;
        }
      }
    }
    .slick-dots {
      margin: 0;
      bottom: -45px;
      li {
        padding: 0;
      }
    }
    .bt {
      margin: 60px 0 0;
    }
  }
}
/************************************

.topmenu

************************************/
#top {
  .topmenu {
    margin: 0 0 50px;
    position: relative;
    .wrapper {
      display: block; /* before Android4.3 iOS6:Safari */
      display: -ms-flexbox; /*IE10*/
      display: -webkit-flex; /* Safari */
      display: flex;
      -webkit-flex-wrap: wrap; /* Safari */
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    &.right .wrapper {
      justify-content: flex-end;
    }
  }

  .waku {
    width: 70vw;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 18vw 3vw 0;
    display: block; /* before Android4.3 iOS6:Safari */
    display: -ms-flexbox; /*IE10*/
    display: -webkit-flex; /* Safari */
    display: flex;
    -webkit-flex-wrap: wrap; /* Safari */
    flex-wrap: wrap;
    justify-content: flex-start;
    @include media-tab {
      width: 100%;
      padding: 30vw 3vw 0;
    }
    @include media-sp {
      padding: 40vw 3vw 0;
    }
    .text {
      width: 32vw;
      padding: 3vw;
      background-color: #fff;
      text-align: left;
      @include media-tab {
        width: 80%;
        padding: 5vw;
      }
      @include media-sp {
        width: 100%;
      }
      h2 {
        font-size: 2.1rem;
        margin: 0 0 10px;
        @include media-tab {
          font-size: 1.8rem;
          margin: 0 0 5px;
        }
      }
      p {
        font-size: 1.5rem;
        margin: 0 0 1em;
        @include media-tab {
          font-size: 1.3rem;
        }
      }
    }
  }
  .topmenu.left .waku {
    justify-content: flex-start;
  }
  .topmenu.right .waku {
    justify-content: flex-end;
  }
  #wholesale .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img-sp@2x.jpg");
    }
    &::before {
      position: absolute;
      top: 30%;
      right: 0;
      width: 80vw;
      height: 40vw;
      background-color: $back-color1;
      content: "";
      z-index: -1;
      @include media-sp {
        height: 140vw;
      }
    }
  }
  #athome .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img-sp@2x.jpg");
    }
    &::before {
      position: absolute;
      top: 60%;
      left: 0;
      width: 80vw;
      height: 40vw;
      background-color: $back-color1;
      content: "";
      z-index: -1;
      @include media-sp {
        height: 140vw;
      }
    }
  }
  #shop .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img-sp@2x.jpg");
    }
  }
}
/************************************

.topmenu

************************************/
#top {
  #etc_menu {
    margin: 0 0 50px;
    position: relative;
    ul {
      display: block; /* before Android4.3 iOS6:Safari */
      display: -ms-flexbox; /*IE10*/
      display: -webkit-flex; /* Safari */
      display: flex;
      -webkit-flex-wrap: wrap; /* Safari */
      flex-wrap: wrap;
      li {
        width: 50%;
        @include media-sp {
          width: 100%;
          margin: 0 0 10px;
        }
        a {
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          width: 100%;
          height: 0;
          padding-top: 50%;
          display: block;
          position: relative;
          h2 {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            font-size: 2.4rem;
            letter-spacing: 0.1em;
            color: #fff;
            @include media-tab {
              font-size: 1.9rem;
            }
            &::before {
              position: absolute;
              top: -50px;
              left: 0px;
              right: 0px;
              margin: auto;
              width: 50px;
              height: 50px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              content: "";
              @include media-tab {
                width: 35px;
                height: 35px;
                top: -35px;
              }
            }
          }
        }
        &:nth-child(1) a {
          background-image: url("/wp/wp-content/themes/itsuki02/images/company_img.jpg");
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
            background-image: url("/wp/wp-content/themes/itsuki02/images/company_img@2x.jpg");
          }
          h2::before {
            background-image: url("/wp/wp-content/themes/itsuki02/images/company_mark@2x.png");
          }
        }
        &:nth-child(2) a {
          background-image: url("/wp/wp-content/themes/itsuki02/images/contact_img.jpg");
          @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
            background-image: url("/wp/wp-content/themes/itsuki02/images/contact_img@2x.jpg");
          }
          h2::before {
            background-image: url("/wp/wp-content/themes/itsuki02/images/company_mark@2x.png");
          }
        }
      }
    }
  }
}
