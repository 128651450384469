@charset "UTF-8";

// common.scssにimport
/**=================================

ここからお問い合わせ(_contact.scss)

=================================**/

/************************************

#mainimage

************************************/
#contact {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#contat_form

************************************/
#contat_form {
  p {
    margin: 0 0 50px;
    @include media-tab {
      font-size: 1.4rem;
      margin: 0 0 30px;
    }
    @include media-sp {
      text-align: left;
    }
  }
  .tabs {
    margin-top: 50px;
    background-color: $back-color1;
    width: 100%;
    margin: 0 auto;
    padding: 1% 0 0 1%;
  }
  .tab_item {
    width: calc(96% / 4);
    background-color: #dcdcd2;
    font-size: 1.6rem;
    text-align: center;
    display: block;
    margin: 0 1% 1% 0;
    padding: 15px 0;
    float: left;
    transition: all 0.2s ease;
    position: relative;
    @include media-tab {
      font-size: 1.3rem;
    }
    @include media-sp {
      width: calc(98% / 2);
    }
    &::before {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%); /* Safari用 */
      transform: translateY(-50%);
      right: 10px;
      width: 15px;
      height: 15px;
      background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_black@2x.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      content: "";
      z-index: 5;
      @include media-tab {
        width: 10px;
        height: 10px;
      }
    }
  }
  .tab_item:hover {
    opacity: 0.75;
    @include media-tab {
      opacity: 1;
    }
  }
  input[name="tab_item"] {
    display: none;
  }

  #recruit:checked ~ #recruit_content,
  #wholesale:checked ~ #wholesale_content,
  #media:checked ~ #media_content,
  #etc:checked ~ #etc_content {
    display: block;
  }
  .tabs input:checked + .tab_item {
    background-color: $main-color;
    color: #fff;
    &::before {
      background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_white@2x.png");
    }
  }
  .tab_content {
    display: none;
    padding: 5%;
    clear: both;
    overflow: hidden;
    h3 {
      font-size: 2.1rem;
      @include media-tab {
        font-size: 1.9rem;
      }
      @include media-sp {
        margin: 20px 0 15px;
      }
    }
  }
  .tab_content.inputform {
    h4 {
      display: none;
    }
    .form-field2 h4 {
      display: block;
    }
  }
  .contact-form {
    max-width: 700px;
    margin: 0 auto;
    .form-field {
      position: relative;
      margin: 40px 0;
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: $main-color;
      text-align: left;
      @include clearfix;
      h4 {
        font-size: 1.6rem;
        line-height: 26px;
        color: #888;
        float: left;
        width: 25%;
        @include media-tab {
          font-size: 1.4rem;
        }
        @include media-sp {
          float: none;
          width: 100%;
        }
      }
      &.form-field2 {
        padding-bottom: 10px;
      }
    }

    .input-text {
      display: block;
      width: 100%;
      height: 36px;
      border: none;
      background-color: $back-color1;
      font-size: 1.6rem;
      line-height: 1.6rem;
      @include media-tab {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }

      &:focus {
        outline: none;
      }

      &:focus,
      &.not-empty {
        //background-color: #fff;
        + .label {
          transform: translateY(-24px);
        }
      }
    }
    textarea.input-text {
      height: 100px;
      font-size: 1.6rem;
      line-height: 1.8;
      @include media-tab {
        font-size: 1.4rem;
      }
      &:focus {
        outline: none;
      }

      &:focus,
      &.not-empty {
        + .label {
          transform: translateY(-30px);
        }
      }
    }
    .label {
      position: absolute;
      left: 0px;
      bottom: 11px;
      font-size: 1.6rem;
      line-height: 26px;
      color: #888;
      cursor: text;
      transition: transform 0.2s ease-in-out;
      @include media-tab {
        font-size: 1.4rem;
      }
    }
    .label.textarea {
      top: 0px;
      bottom: auto;
    }
    .radio_label {
      margin: 0 20px 0 0;
      @include media-tab {
        font-size: 1.4rem;
      }
      input[type="radio"],
      input[type="checkbox"] {
        margin: 0 5px 0 0;
      }
    }
    .selectstyle {
      position: relative;
      display: inline-block;
      background: #fefefe;
      background: linear-gradient(to bottom, #ffffff 0%, #fff 60%, #eee 100%);
      border-radius: 3px;
      font-size: 1.6rem;
      overflow: hidden;
      z-index: 0;
      padding-right: 0px;
      vertical-align: middle;
      @include media-tab {
        font-size: 1.4rem;
      }
      @include media-sp {
        margin: 10px 0 0;
        max-width: 100%;
        overflow: hidden;
      }
    }
    .selectstyle:after {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 15px;
      width: 12px;
      height: 12px;
      background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_black@2x.png");
      background-size: contain;
      content: "";
    }
    .selectstyle select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0.4em 2em 0.4em 1.5em;
      color: #000;
      background: transparent;
      border-radius: 3px;
      font-size: 100%;
      outline: none;
    }
    .selectstyle select::-ms-expand {
      display: none;
    }
    .selectstyle select::-moz-focus-inner {
      border: 0;
    }

    .submit-btn {
      background-color: $main-color;
      border: none;
      color: #fff;
      -webkit-appearance: none;
      border-radius: 0;
      font-size: 1.6rem;
      padding: 12px 30px;
      display: inline-block;
      margin: 0 auto;
      cursor: pointer;
      @include media-sp {
        display: block;
        width: 100%;
        margin: 0 auto;
        max-width: 350px;
      }
    }
  }
  /* 確認画面用 */
  .tab_content.confirm {
    display: block;
    .form-field {
      padding-bottom: 10px;
    }
    h4 {
      display: block;
    }
    .text {
      margin-left: 27%;
      @include media-tab {
        font-size: 1.4rem;
      }
      @include media-sp {
        margin: 0;
      }
    }
    .selectstyle {
      position: relative;
      display: inline-block;
      background: none;
      border-radius: 0;
      font-size: 1em;
      overflow: inherit;
      z-index: 0;
      padding-right: 0px;
    }
    .selectstyle:after {
      display: none;
    }
  }

  /*  thanks */
  .thanks {
    p {
      line-height: 2;
    }
    @include media-tab {
      margin: 0 3%;
    }
  }
}
