@charset "UTF-8";

//setting
$main-color: #333333;
$back-color1: #f8f8f6;
$back-color2: #f0f0eb;

$font-family-go: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3",
  "メイリオ", Meiryo, sans-serif;
$font-family-min: "游明朝", "YuMincho", "Noto Serif JP", "Hiragino Mincho ProN", "HGS明朝E", serif;

$font-family-eng: "Cinzel", serif;

//base letter-space
$letterspace: 0.03em;

// media query
$tab: 960px; // タブレット
$sp: 480px; // スマホ

@mixin media-tab {
  @media only screen and (max-width: ($tab)) {
    @content;
  }
}
// 呼び出すときは　@include media-tab { }
@mixin media-sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}
// 呼び出すときは　@include media-sp { }

//clearfix 呼び出すときは　@include clearfix;
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
