@charset "UTF-8";

// common.scssにimport
/**=================================

ここからSHOP紹介(_shop.scss)

=================================**/

/************************************

#mainimage

************************************/
#shop {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#shop_info

************************************/
#shop_info {
  p {
    margin: 0 0 50px;
    @include media-tab {
      font-size: 1.4rem;
      margin: 0 2% 30px;
      text-align: left;
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .waku {
      width: 46%;
      margin: 0 2% 30px;
      text-align: left;
      @include clearfix;
      @include media-sp {
        width: 96%;
        margin: 0 2% 30px;
      }
      a {
        display: block;
      }
      .title {
        margin: 25px 0 10px;
        position: relative;
        h3 {
          font-size: 2.2rem;
          line-height: 1.2;
          margin: 0 110px 20px 0;
          @include media-tab {
            font-size: 1.8rem;
          }
        }
        .bt {
          position: absolute;
          right: 0;
          top: 0;
          width: 100px;
          background-color: $main-color;
          color: #fff;
          text-align: center;
          font-size: 1.4rem;
          line-height: 1;
          padding: 10px 0;
          @include media-tab {
            font-size: 1.2rem;
            padding: 5px 0;
            width: 80px;
          }
          &::before {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 6px;
            width: 18px;
            height: 10.6px;
            background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji_o@2x.png");
            background-size: contain;
            background-position: right center;
            content: "";
            z-index: 2;
            transition: 0.3s;
            @include media-tab {
              right: 3px;
              width: 15px;
            }
          }
        }
      }
      p {
        font-size: 1.5rem;
        margin: 0 0 20px;
        @include media-tab {
          font-size: 1.4rem;
          margin: 0 0 10px;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0;
          background-color: $back-color2;
          font-size: 1.4rem;
          line-height: 1;
          padding: 7px 15px;
          @include media-tab {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
