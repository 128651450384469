@charset "UTF-8";

// common.scssにimport
/**=================================

ここから会社概要(_company.scss)

=================================**/

/************************************

#mainimage

************************************/
#company {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#company_info

************************************/
#company_info {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 700px;
  }
  dl {
    @include clearfix;
    padding: 15px;
    text-align: left;
    line-height: 1.8;
    border-bottom: 1px solid $main-color;
    dt {
      float: left;
      width: 25%;
    }
    dd {
      float: right;
      width: 70%;
    }
  }
}
#company #access {
  iframe {
    width: 100%;
    height: 400px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    @include media-tab {
      height: 280px;
    }
  }
}
