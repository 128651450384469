@charset "UTF-8";

// common.scssにimport
/**=================================

ここから伊都岐珈琲の紹介(_about.scss)

=================================**/

/************************************

#mainimage

************************************/
#about {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#about_info

************************************/
#about_info {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 700px;
  }
  h2 {
    position: relative;
    display: inline-block;
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
    margin: 0 0 50px;
    padding: 20px 50px;
    @include media-tab {
      font-size: 2.1rem;
      padding: 15px 30px;
      margin: 0 0 30px;
    }
    @include media-sp {
      font-size: 5vw;
      padding: 15px 0;
      display: block;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0px;
      width: 50px;
      height: 50px;
      border-top: 1px solid $main-color;
      border-left: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
    &::after {
      position: absolute;
      bottom: 0;
      right: 0px;
      width: 50px;
      height: 50px;
      border-bottom: 1px solid $main-color;
      border-right: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
  }
  p {
    margin: 0 0 50px;
    line-height: 2;
    text-align: left;
    @include media-tab {
      font-size: 1.4rem;
      margin: 0 0 25px;
    }
  }
}
/************************************

.layout2

************************************/
#about {
  .layout2 {
    margin: 0 0 80px;
    @include clearfix;
    @include media-tab {
      margin: 0 0 40px;
    }
    .text {
      float: left;
      width: 25%;
      margin: 20px 5% 0 10%;
      text-align: left;
      @include media-tab {
        float: none;
        width: auto;
        margin: 0 5% 20px;
      }
      h2 {
        font-size: 2.2rem;
        line-height: 1.6;
        letter-spacing: 0.1em;
        margin: 0 0 30px;
        @include media-tab {
          font-size: 1.9rem;
          margin: 0 0 15px;
        }
      }
      p {
        line-height: 2;
        @include media-tab {
          font-size: 1.4rem;
        }
      }
    }
    .image {
      float: left;
      width: 60%;
      @include media-tab {
        float: none;
        width: auto;
        margin: 0 5% 20px;
      }
    }
  }
  #about_shinmai.layout2 {
    .text {
      float: right;
      margin: 0 5% 20px;
    }
    .image {
      float: right;
    }
  }
}
/************************************

#about_special

************************************/
#about_special {
  background-color: $back-color1;
  padding: 4%;
  @include media-tab {
    padding: 8%;
  }
  .wrapper_s {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    @include media-tab {
      display: block;
    }
  }
  .text {
    width: 43%;
    text-align: left;
    @include media-tab {
      width: 100%;
      margin: 0 0 10px;
    }
    h2 {
      font-size: 2.2rem;
      margin: 0 0 20px;
      @include media-tab {
        font-size: 1.9rem;
        margin: 0 0 10px;
      }
    }
    p {
      line-height: 2;
    }
  }
  .image {
    width: 50%;
    @include media-tab {
      width: 100%;
    }
  }
}
