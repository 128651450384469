@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */
html {
  font-size: 62.5%;
}

body {
  font-family: "游明朝", "YuMincho", "Noto Serif JP", "Hiragino Mincho ProN", "HGS明朝E", serif;
  text-align: center;
  color: #333333;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  font-size: 1.6em;
  letter-spacing: 0.03em;
  line-height: 1.8;
}

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  vertical-align: bottom;
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media only screen and (max-width: 960px) {
  img {
    border: 0;
    max-width: 100%;
    height: auto;
  }
}

a {
  color: #333333;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {
  opacity: 0.7;
}

a img {
  border: none;
}

i {
  padding: 0 5px;
}

ul {
  letter-spacing: -1em;
}

ul li {
  letter-spacing: 0.03em;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.pc {
  display: block;
}

@media only screen and (max-width: 960px) {
  .pc {
    display: none;
  }
}

.tablet {
  display: none;
}

@media only screen and (max-width: 960px) {
  .tablet {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .tablet {
    display: none;
  }
}

.sp {
  display: none;
}

@media only screen and (max-width: 960px) {
  .sp {
    display: block;
  }
}

.ssp {
  display: none;
}

@media only screen and (max-width: 480px) {
  .ssp {
    display: block;
  }
}

/*PC改行加える*/
.br-pc {
  display: inherit;
}

@media only screen and (max-width: 960px) {
  .br-pc {
    display: none;
  }
}

/*PC改行無効*/
.br_sp {
  display: none;
}

@media only screen and (max-width: 960px) {
  .br_sp {
    display: block;
  }
}

.br_ssp {
  display: none;
}

@media only screen and (max-width: 480px) {
  .br_ssp {
    display: block;
  }
}

/************************************

wrapper

************************************/
.wrapper {
  max-width: 1600px;
  width: calc(100% - 80px);
  margin: 0 auto;
}

.wrapper::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  .wrapper {
    width: 92%;
    margin: auto;
  }
}

.wrapper_s {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
}

.wrapper_s::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  .wrapper_s {
    width: 100%;
  }
}

/************************************

.list(flexbox)

************************************/
.list {
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.list::after {
  content: "";
  display: block;
  clear: both;
}

.list article {
  width: auto;
}

/************************************

header

************************************/
header {
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 15px 0;
  height: 110px;
  background-color: #fff;
}

header::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  header {
    height: auto;
    position: relative;
  }
}

header h1 {
  float: left;
  width: 160px;
}

@media only screen and (max-width: 960px) {
  header h1 {
    float: none;
    width: 120px;
    margin: 0 auto;
  }
}

header h1 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 0;
  width: 100%;
  padding-top: 50%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  header h1 a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
  }
}

header nav {
  margin: 0px 0 0 200px;
}

@media only screen and (max-width: 960px) {
  header nav {
    display: none;
  }
}

header nav ul {
  padding: 40px 0 0;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
}

header nav ul::after {
  content: "";
  display: block;
  clear: both;
}

header nav ul li {
  width: auto;
  margin: 0 14px;
  font-size: 1.5rem;
}

header .submenu {
  opacity: 0;
  height: 0;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 11;
  transition: 0.7s;
}

header .submenu .submenu2 {
  padding: 0;
  display: none;
}

header nav li:hover .submenu {
  opacity: 1;
  height: auto;
  padding: 20px 0 0;
}

header nav li:hover .submenu .submenu2 {
  display: block;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

header nav li:hover .submenu ul {
  padding: 0;
}

/************************************

#sidemenu

************************************/
#sidemenu {
  position: fixed;
  right: 0;
  top: 30%;
  width: 53px;
  z-index: 10;
}

@media only screen and (max-width: 960px) {
  #sidemenu {
    right: auto;
    left: 10px;
    top: auto;
    bottom: 0;
    width: 250px;
  }
  #sidemenu ul {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

#sidemenu li a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  #sidemenu li a {
    margin: 0;
  }
}

#sidemenu li.onlineshop a {
  background-image: url("/wp/wp-content/themes/itsuki02/images/side_online.png");
  width: 53px;
  height: 297px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #sidemenu li.onlineshop a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/side_online@2x.png");
  }
}

@media only screen and (max-width: 960px) {
  #sidemenu li.onlineshop a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/bottom_online@2x.png");
    width: 200px;
    height: 40px;
  }
}

#sidemenu li.facebook a {
  margin: auto;
}

@media only screen and (max-width: 960px) {
  #sidemenu li.facebook a {
    margin: 5px;
  }
}

.facebook a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  background-image: url("/wp/wp-content/themes/itsuki02/images/facebook.png");
  width: 30px;
  height: 30px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .facebook a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/facebook@2x.png");
  }
}

/************************************

footer

************************************/
footer {
  padding: 50px 0;
}

footer::after {
  content: "";
  display: block;
  clear: both;
}

footer h2 {
  margin: 0 auto 50px;
  width: 160px;
}

@media only screen and (max-width: 960px) {
  footer h2 {
    width: 120px;
  }
}

footer h2 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 0;
  width: 100%;
  padding-top: 50%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  footer h2 a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
  }
}

footer ul {
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row-reverse;
  text-align: left;
}

footer ul li {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  margin: 0 15px;
}

@media only screen and (max-width: 960px) {
  footer ul li {
    font-size: 1.4rem;
    margin: 0 5px;
  }
}

footer .facebook a {
  margin: 40px auto;
}

@media only screen and (max-width: 960px) {
  footer .facebook a {
    display: none;
  }
}

footer .copyright {
  font-size: 1rem;
  letter-spacing: 0.5em;
}

@media only screen and (max-width: 960px) {
  footer .copyright {
    margin: 30px 0 0;
  }
}

/************************************

PageUP

************************************/
#pageup {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  z-index: 10;
  font-size: 8px;
  transition: 0.5s;
}

@media only screen and (max-width: 960px) {
  #pageup {
    bottom: 0px;
    right: 0px;
    font-size: 4px;
  }
}

#pageup.none {
  display: none;
}

#pageup a {
  width: 50px;
  display: block;
  text-decoration: none;
  line-height: 100%;
  color: #333;
}

#pageup i {
  font-size: 40px;
  line-height: 0.8;
}

@media only screen and (max-width: 960px) {
  #pageup i {
    font-size: 36px;
  }
}

/************************************

drawer

************************************/
.drawer-nav {
  width: 27rem;
  color: #333333;
}

.drawer--right .drawer-nav {
  right: -27rem;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 27rem;
}

.drawer-hamburger {
  width: 3rem;
  padding-top: 14px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 10ox;
  background-color: transparent;
}

.drawer-menu {
  padding: 5%;
}

.drawer-menu h1 {
  margin: 30px auto;
  width: 120px;
}

.drawer-menu h1 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 0;
  width: 100%;
  padding-top: 50%;
}

.drawer-menu li {
  font-family: "游明朝", "YuMincho", "Noto Serif JP", "Hiragino Mincho ProN", "HGS明朝E", serif;
  margin: 0 0 20px;
  font-size: 1.5rem;
  letter-spacing: 0.08em;
}

.drawer-menu li ul {
  margin: 20px 0 0;
}

.drawer-menu li ul li {
  display: inline-block;
  padding: 0 4px 0 10px;
  margin: 0 0 15px;
  font-size: 1.2rem;
  position: relative;
}

.drawer-menu li ul li::before {
  position: absolute;
  top: 0;
  left: 0px;
  content: "/";
}

.drawer-menu li ul li:first-child::before, .drawer-menu li ul li:nth-child(4)::before {
  content: "";
}

/************************************

main

************************************/
main {
  display: block;
  padding-top: 110px;
}

@media only screen and (max-width: 960px) {
  main {
    padding: 0;
  }
}

main section {
  opacity: 0;
  transform: translate(0, 60px);
  -webkit-transform: translate(0, 60px);
  transition: 1s;
}

main section.mv07 {
  opacity: 1;
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
}

main section .white,
main section .beige {
  padding: 80px 0;
}

@media only screen and (max-width: 960px) {
  main section .white,
  main section .beige {
    padding: 50px 0;
  }
}

main section .white {
  background-color: #fff;
}

main section .beige {
  background-color: #f8f8f6;
}

.fadein {
  opacity: 0;
  transition: 1s;
}

.fadein.mv_fadein {
  opacity: 1;
}

.eng_midashi {
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 0 40px;
}

@media only screen and (max-width: 960px) {
  .eng_midashi {
    font-size: 1.2rem;
    margin: 0 0 25px;
  }
}

.eng_midashi .eng {
  font-size: 2.8rem;
  display: block;
  margin: 0 0 5px;
}

@media only screen and (max-width: 960px) {
  .eng_midashi .eng {
    font-size: 2.4rem;
  }
}

.bt a {
  border: 1px solid #333333;
  display: inline-block;
  margin: 0 0px;
  font-size: 1.6rem;
  padding: 10px 90px 10px 70px;
  position: relative;
  transition: 0.3s;
}

@media only screen and (max-width: 960px) {
  .bt a {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  .bt a {
    padding: 10px 0;
    display: block;
    text-align: center !important;
    margin: 0;
  }
}

.bt a:hover {
  color: #fff;
  background-color: #333333;
  opacity: 1;
}

.bt a::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Safari用 */
  transform: translateY(-50%);
  right: 20px;
  width: 27px;
  height: 16px;
  background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji@2x.png");
  background-size: contain;
  content: "";
  transition: 0.3s;
}

.bt a:hover::before {
  right: 10px;
  opacity: 1;
  background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji_o@2x.png");
}

#mainimage_second {
  width: 100%;
  height: 0;
  padding-top: 30%;
  margin: 0 auto 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 960px) {
  #mainimage_second {
    margin: 0 auto 40px;
  }
}

@media only screen and (max-width: 480px) {
  #mainimage_second {
    padding-top: 50%;
  }
}

/**=================================

ここからトップページ(_top.scss)

=================================**/
/************************************

#mainimage

************************************/
#top #mainimage {
  margin: 0 auto 30px;
}

#top #mainimage .mainslide_waku {
  width: 100%;
  height: calc(100vh - 110px);
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 960px) {
  #top #mainimage .mainslide_waku {
    height: 45vw;
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku {
    height: 95vw;
  }
}

#top #mainimage .mainslide_waku .copy {
  position: absolute;
  width: 86%;
  bottom: 7%;
  left: 7%;
  color: #fff;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #top #mainimage .mainslide_waku .copy {
    top: 30%;
    bottom: auto;
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku .copy {
    top: auto;
    bottom: 7%;
  }
}

#top #mainimage .mainslide_waku .copy h2 {
  font-size: 4.8rem;
}

@media only screen and (max-width: 960px) {
  #top #mainimage .mainslide_waku .copy h2 {
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku .copy h2 {
    font-size: 2.1rem;
  }
}

#top #mainimage .mainslide_waku .copy p {
  font-size: 1.6rem;
}

@media only screen and (max-width: 960px) {
  #top #mainimage .mainslide_waku .copy p {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku .copy p {
    font-size: 1.1rem;
  }
}

#top #mainimage .mainslide_waku:nth-child(1) {
  background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage1.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #mainimage .mainslide_waku:nth-child(1) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage1@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku:nth-child(1) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp1@2x.jpg");
  }
}

#top #mainimage .mainslide_waku:nth-child(2) {
  background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage2.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #mainimage .mainslide_waku:nth-child(2) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage2@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku:nth-child(2) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp2@2x.jpg");
  }
}

#top #mainimage .mainslide_waku:nth-child(3) {
  background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage3.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #mainimage .mainslide_waku:nth-child(3) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage3@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #mainimage .mainslide_waku:nth-child(3) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/mainimage-sp3@2x.jpg");
  }
}

/************************************

#information

************************************/
#top #information {
  width: 100%;
  margin: 0 auto 30px;
  padding: 50px 0;
  background-color: #f8f8f6;
}

@media only screen and (max-width: 960px) {
  #top #information {
    padding: 30px 0;
  }
}

#top #information ul {
  display: block;
  /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox;
  /*IE10*/
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  margin: 0 0 30px;
}

@media only screen and (max-width: 960px) {
  #top #information ul {
    margin: 0 0 20px;
  }
}

#top #information ul li {
  width: 46%;
  margin: 10px 2%;
  text-align: left;
}

#top #information ul li::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  #top #information ul li {
    width: 100%;
    margin: 10px 0;
  }
}

#top #information ul li a {
  display: block;
}

#top #information .image {
  float: left;
  width: 25%;
}

@media only screen and (max-width: 960px) {
  #top #information .image {
    width: 35%;
  }
}

#top #information .text {
  float: right;
  width: 70%;
}

@media only screen and (max-width: 960px) {
  #top #information .text {
    width: 60%;
  }
}

#top #information .text .date {
  font-size: 1.1rem;
}

#top #information .text h3 {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media only screen and (max-width: 960px) {
  #top #information .text h3 {
    font-size: 1.3rem;
  }
}

/************************************

#about

************************************/
#top #about {
  width: 100%;
  margin: 0 auto 50px;
  background-image: url("/wp/wp-content/themes/itsuki02/images/about_img.jpg");
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 14vw 0 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #about {
    background-image: url("/wp/wp-content/themes/itsuki02/images/about_img@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #about {
    background-image: url("/wp/wp-content/themes/itsuki02/images/about_img-sp@2x.jpg");
    padding: 30vw 0 0;
  }
}

#top #about .text {
  margin: 0 auto;
  padding: 70px 0 0;
  background-color: #fff;
  width: 330px;
}

@media only screen and (max-width: 960px) {
  #top #about .text {
    padding: 40px 0 0;
    width: 250px;
  }
}

@media only screen and (max-width: 480px) {
  #top #about .text {
    width: 200px;
  }
}

#top #about .text h2 {
  margin: 0 auto 70px;
  width: 160px;
}

@media only screen and (max-width: 960px) {
  #top #about .text h2 {
    width: 120px;
    margin: 0 auto 40px;
  }
}

#top #about .text h2 a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  height: 0;
  width: 100%;
  padding-top: 50%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #about .text h2 a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
  }
}

#top #about .text p {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-align: left;
  line-height: 2.2;
  display: inline-block;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  #top #about .text p {
    font-size: 1.4rem;
  }
}

/************************************

#lineup

************************************/
#top #lineup {
  width: 100%;
  margin: 0 auto 30px;
  padding: 50px 0;
  background-color: #f8f8f6;
}

@media only screen and (max-width: 960px) {
  #top #lineup {
    padding: 30px 0;
  }
}

#top #lineup ul {
  margin: 0 0 30px;
}

#top #lineup ul::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  #top #lineup ul {
    margin: 0 0 20px;
  }
}

#top #lineup ul .slick-list::after {
  content: "";
  display: block;
  clear: both;
}

#top #lineup ul li {
  text-align: left;
  padding: 0 30px;
}

@media only screen and (max-width: 960px) {
  #top #lineup ul li {
    padding: 0 10px;
  }
}

#top #lineup ul li a {
  display: block;
}

#top #lineup .text {
  margin: 1em 0 0;
}

#top #lineup .text h3 {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 0 0 1em;
}

@media only screen and (max-width: 960px) {
  #top #lineup .text h3 {
    font-size: 1.3rem;
  }
}

#top #lineup .text p {
  font-size: 1.4rem;
}

@media only screen and (max-width: 960px) {
  #top #lineup .text p {
    font-size: 1.1rem;
  }
}

#top #lineup .slick-dots {
  margin: 0;
  bottom: -45px;
}

#top #lineup .slick-dots li {
  padding: 0;
}

#top #lineup .bt {
  margin: 60px 0 0;
}

/************************************

.topmenu

************************************/
#top .topmenu {
  margin: 0 0 50px;
  position: relative;
}

#top .topmenu .wrapper {
  display: block;
  /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox;
  /*IE10*/
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  justify-content: flex-start;
}

#top .topmenu.right .wrapper {
  justify-content: flex-end;
}

#top .waku {
  width: 70vw;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  padding: 18vw 3vw 0;
  display: block;
  /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox;
  /*IE10*/
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media only screen and (max-width: 960px) {
  #top .waku {
    width: 100%;
    padding: 30vw 3vw 0;
  }
}

@media only screen and (max-width: 480px) {
  #top .waku {
    padding: 40vw 3vw 0;
  }
}

#top .waku .text {
  width: 32vw;
  padding: 3vw;
  background-color: #fff;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #top .waku .text {
    width: 80%;
    padding: 5vw;
  }
}

@media only screen and (max-width: 480px) {
  #top .waku .text {
    width: 100%;
  }
}

#top .waku .text h2 {
  font-size: 2.1rem;
  margin: 0 0 10px;
}

@media only screen and (max-width: 960px) {
  #top .waku .text h2 {
    font-size: 1.8rem;
    margin: 0 0 5px;
  }
}

#top .waku .text p {
  font-size: 1.5rem;
  margin: 0 0 1em;
}

@media only screen and (max-width: 960px) {
  #top .waku .text p {
    font-size: 1.3rem;
  }
}

#top .topmenu.left .waku {
  justify-content: flex-start;
}

#top .topmenu.right .waku {
  justify-content: flex-end;
}

#top #wholesale .waku {
  background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #wholesale .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #wholesale .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/wholesale_img-sp@2x.jpg");
  }
}

#top #wholesale .waku::before {
  position: absolute;
  top: 30%;
  right: 0;
  width: 80vw;
  height: 40vw;
  background-color: #f8f8f6;
  content: "";
  z-index: -1;
}

@media only screen and (max-width: 480px) {
  #top #wholesale .waku::before {
    height: 140vw;
  }
}

#top #athome .waku {
  background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #athome .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #athome .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/athome_img-sp@2x.jpg");
  }
}

#top #athome .waku::before {
  position: absolute;
  top: 60%;
  left: 0;
  width: 80vw;
  height: 40vw;
  background-color: #f8f8f6;
  content: "";
  z-index: -1;
}

@media only screen and (max-width: 480px) {
  #top #athome .waku::before {
    height: 140vw;
  }
}

#top #shop .waku {
  background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #shop .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #top #shop .waku {
    background-image: url("/wp/wp-content/themes/itsuki02/images/shop_img-sp@2x.jpg");
  }
}

/************************************

.topmenu

************************************/
#top #etc_menu {
  margin: 0 0 50px;
  position: relative;
}

#top #etc_menu ul {
  display: block;
  /* before Android4.3 iOS6:Safari */
  display: -ms-flexbox;
  /*IE10*/
  display: -webkit-flex;
  /* Safari */
  display: flex;
  -webkit-flex-wrap: wrap;
  /* Safari */
  flex-wrap: wrap;
}

#top #etc_menu ul li {
  width: 50%;
}

@media only screen and (max-width: 480px) {
  #top #etc_menu ul li {
    width: 100%;
    margin: 0 0 10px;
  }
}

#top #etc_menu ul li a {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 50%;
  display: block;
  position: relative;
}

#top #etc_menu ul li a h2 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  color: #fff;
}

@media only screen and (max-width: 960px) {
  #top #etc_menu ul li a h2 {
    font-size: 1.9rem;
  }
}

#top #etc_menu ul li a h2::before {
  position: absolute;
  top: -50px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
}

@media only screen and (max-width: 960px) {
  #top #etc_menu ul li a h2::before {
    width: 35px;
    height: 35px;
    top: -35px;
  }
}

#top #etc_menu ul li:nth-child(1) a {
  background-image: url("/wp/wp-content/themes/itsuki02/images/company_img.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #etc_menu ul li:nth-child(1) a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/company_img@2x.jpg");
  }
}

#top #etc_menu ul li:nth-child(1) a h2::before {
  background-image: url("/wp/wp-content/themes/itsuki02/images/company_mark@2x.png");
}

#top #etc_menu ul li:nth-child(2) a {
  background-image: url("/wp/wp-content/themes/itsuki02/images/contact_img.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #top #etc_menu ul li:nth-child(2) a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/contact_img@2x.jpg");
  }
}

#top #etc_menu ul li:nth-child(2) a h2::before {
  background-image: url("/wp/wp-content/themes/itsuki02/images/company_mark@2x.png");
}

/**=================================

ここから伊都岐珈琲の紹介(_about.scss)

=================================**/
/************************************

#mainimage

************************************/
#about #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #about #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #about #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/about/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#about_info

************************************/
#about_info {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #about_info {
    margin: 0 0 40px;
  }
}

#about_info .wrapper_s {
  max-width: 700px;
}

#about_info h2 {
  position: relative;
  display: inline-block;
  font-size: 2.7rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin: 0 0 50px;
  padding: 20px 50px;
}

@media only screen and (max-width: 960px) {
  #about_info h2 {
    font-size: 2.1rem;
    padding: 15px 30px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 480px) {
  #about_info h2 {
    font-size: 5vw;
    padding: 15px 0;
    display: block;
  }
}

#about_info h2::before {
  position: absolute;
  top: 0;
  left: 0px;
  width: 50px;
  height: 50px;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #about_info h2::before {
    width: 30px;
    height: 30px;
  }
}

#about_info h2::after {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #333333;
  border-right: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #about_info h2::after {
    width: 30px;
    height: 30px;
  }
}

#about_info p {
  margin: 0 0 50px;
  line-height: 2;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #about_info p {
    font-size: 1.4rem;
    margin: 0 0 25px;
  }
}

/************************************

.layout2

************************************/
#about .layout2 {
  margin: 0 0 80px;
}

#about .layout2::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 960px) {
  #about .layout2 {
    margin: 0 0 40px;
  }
}

#about .layout2 .text {
  float: left;
  width: 25%;
  margin: 20px 5% 0 10%;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #about .layout2 .text {
    float: none;
    width: auto;
    margin: 0 5% 20px;
  }
}

#about .layout2 .text h2 {
  font-size: 2.2rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
  margin: 0 0 30px;
}

@media only screen and (max-width: 960px) {
  #about .layout2 .text h2 {
    font-size: 1.9rem;
    margin: 0 0 15px;
  }
}

#about .layout2 .text p {
  line-height: 2;
}

@media only screen and (max-width: 960px) {
  #about .layout2 .text p {
    font-size: 1.4rem;
  }
}

#about .layout2 .image {
  float: left;
  width: 60%;
}

@media only screen and (max-width: 960px) {
  #about .layout2 .image {
    float: none;
    width: auto;
    margin: 0 5% 20px;
  }
}

#about #about_shinmai.layout2 .text {
  float: right;
  margin: 0 5% 20px;
}

#about #about_shinmai.layout2 .image {
  float: right;
}

/************************************

#about_special

************************************/
#about_special {
  background-color: #f8f8f6;
  padding: 4%;
}

@media only screen and (max-width: 960px) {
  #about_special {
    padding: 8%;
  }
}

#about_special .wrapper_s {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

@media only screen and (max-width: 960px) {
  #about_special .wrapper_s {
    display: block;
  }
}

#about_special .text {
  width: 43%;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #about_special .text {
    width: 100%;
    margin: 0 0 10px;
  }
}

#about_special .text h2 {
  font-size: 2.2rem;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  #about_special .text h2 {
    font-size: 1.9rem;
    margin: 0 0 10px;
  }
}

#about_special .text p {
  line-height: 2;
}

#about_special .image {
  width: 50%;
}

@media only screen and (max-width: 960px) {
  #about_special .image {
    width: 100%;
  }
}

/**=================================

ここからSHOP紹介(_shop.scss)

=================================**/
/************************************

#mainimage

************************************/
#shop #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #shop #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #shop #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/shop/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#shop_info

************************************/
#shop_info p {
  margin: 0 0 50px;
}

@media only screen and (max-width: 960px) {
  #shop_info p {
    font-size: 1.4rem;
    margin: 0 2% 30px;
    text-align: left;
  }
}

#shop_info .list {
  display: flex;
  flex-wrap: wrap;
}

#shop_info .list .waku {
  width: 46%;
  margin: 0 2% 30px;
  text-align: left;
}

#shop_info .list .waku::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 480px) {
  #shop_info .list .waku {
    width: 96%;
    margin: 0 2% 30px;
  }
}

#shop_info .list .waku a {
  display: block;
}

#shop_info .list .waku .title {
  margin: 25px 0 10px;
  position: relative;
}

#shop_info .list .waku .title h3 {
  font-size: 2.2rem;
  line-height: 1.2;
  margin: 0 110px 20px 0;
}

@media only screen and (max-width: 960px) {
  #shop_info .list .waku .title h3 {
    font-size: 1.8rem;
  }
}

#shop_info .list .waku .title .bt {
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
  background-color: #333333;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1;
  padding: 10px 0;
}

@media only screen and (max-width: 960px) {
  #shop_info .list .waku .title .bt {
    font-size: 1.2rem;
    padding: 5px 0;
    width: 80px;
  }
}

#shop_info .list .waku .title .bt::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 6px;
  width: 18px;
  height: 10.6px;
  background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji_o@2x.png");
  background-size: contain;
  background-position: right center;
  content: "";
  z-index: 2;
  transition: 0.3s;
}

@media only screen and (max-width: 960px) {
  #shop_info .list .waku .title .bt::before {
    right: 3px;
    width: 15px;
  }
}

#shop_info .list .waku p {
  font-size: 1.5rem;
  margin: 0 0 20px;
}

@media only screen and (max-width: 960px) {
  #shop_info .list .waku p {
    font-size: 1.4rem;
    margin: 0 0 10px;
  }
}

#shop_info .list .waku ul {
  display: flex;
  flex-wrap: wrap;
}

#shop_info .list .waku ul li {
  margin: 0 5px 5px 0;
  background-color: #f0f0eb;
  font-size: 1.4rem;
  line-height: 1;
  padding: 7px 15px;
}

@media only screen and (max-width: 960px) {
  #shop_info .list .waku ul li {
    font-size: 1.2rem;
  }
}

/**=================================

ここから業務用販売(_wholesale.scss)

=================================**/
/************************************

#mainimage

************************************/
#wholesale #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #wholesale #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #wholesale #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/wholesale/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#wholesale .sec

************************************/
#wholesale .sec {
  margin-top: -110px;
  padding-top: 110px;
}

@media only screen and (max-width: 960px) {
  #wholesale .sec {
    margin-top: 0;
    padding-top: 0;
  }
}

/************************************

#wholesale_info

************************************/
#wholesale_info {
  margin: 0 0 110px;
}

@media only screen and (max-width: 960px) {
  #wholesale_info {
    margin: 0 0 40px;
  }
}

#wholesale_info .inpagenavi li {
  display: inline-block;
  margin: 0 10px;
}

@media only screen and (max-width: 960px) {
  #wholesale_info .inpagenavi li {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 5px auto;
    font-size: 90%;
  }
}

#wholesale_info .inpagenavi li a {
  display: block;
  border: 1px solid #333333;
  padding: 7px 40px 7px 20px;
  border-radius: 2em;
  position: relative;
}

@media only screen and (max-width: 960px) {
  #wholesale_info .inpagenavi li a {
    padding: 7px 0px;
  }
}

#wholesale_info .inpagenavi li a:hover {
  background-color: #333333;
  color: #fff;
  opacity: 1;
}

#wholesale_info .inpagenavi li a::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 12px;
  margin: auto;
  font-family: "FontAwesome";
  content: "\f107";
}

/************************************

#wholesale .lead

************************************/
#wholesale .lead {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #wholesale .lead {
    margin: 0 0 40px;
  }
}

#wholesale .lead .wrapper_s {
  max-width: 700px;
}

@media only screen and (max-width: 960px) {
  #wholesale .lead .wrapper_s {
    max-width: 90%;
  }
}

#wholesale .lead h2 {
  position: relative;
  display: inline-block;
  font-size: 2.7rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin: 0 0 50px;
  padding: 20px 50px;
}

@media only screen and (max-width: 960px) {
  #wholesale .lead h2 {
    font-size: 2.1rem;
    padding: 15px 30px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 480px) {
  #wholesale .lead h2 {
    font-size: 1.8rem;
    padding: 15px 0;
    display: block;
  }
}

#wholesale .lead h2::before {
  position: absolute;
  top: 0;
  left: 0px;
  width: 50px;
  height: 50px;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #wholesale .lead h2::before {
    width: 30px;
    height: 30px;
  }
}

#wholesale .lead h2::after {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #333333;
  border-right: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #wholesale .lead h2::after {
    width: 30px;
    height: 30px;
  }
}

#wholesale .lead p {
  margin: 0 0 50px;
  line-height: 2;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #wholesale .lead p {
    font-size: 1.5rem;
    margin: 0 0 25px;
  }
}

/************************************

#sec1 .kodawari

************************************/
#wholesale h2.sen {
  font-size: 2.4rem;
  letter-spacing: 0.08em;
  display: inline-block;
  position: relative;
  margin: 0 auto 70px;
}

@media only screen and (max-width: 960px) {
  #wholesale h2.sen {
    font-size: 2.1rem;
    letter-spacing: 0.03em;
    margin: 0 auto 50px;
  }
}

#wholesale h2.sen::before {
  position: absolute;
  bottom: -10px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 100px;
  height: 1px;
  background-color: #333333;
  content: "";
}

#wholesale .kodawari {
  margin: 0 0 80px;
  padding: 80px 3%;
  background-color: #f8f8f6;
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari {
    margin: 0 0 40px;
    padding: 40px 3%;
  }
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list {
    margin: 0 auto;
  }
}

#wholesale .kodawari .list article {
  width: 21%;
  margin: 0 2%;
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list article {
    width: 46%;
    margin: 0 2% 2%;
  }
}

@media only screen and (max-width: 480px) {
  #wholesale .kodawari .list article {
    width: 90%;
    margin: 0 auto 30px;
  }
}

#wholesale .kodawari .list article .image img {
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list article .image img {
    max-width: 70%;
    margin: 0 auto;
  }
}

#wholesale .kodawari .list article .text {
  position: relative;
  padding: 40px 0 0;
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list article .text {
    padding: 30px 0 0;
  }
}

#wholesale .kodawari .list article .text .no {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: #c4bdab;
  font-size: 7rem;
  line-height: 1;
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list article .text .no {
    font-size: 5rem;
  }
}

#wholesale .kodawari .list article .text h3 {
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.7;
  margin: 0 0 10px;
}

@media only screen and (max-width: 960px) {
  #wholesale .kodawari .list article .text h3 {
    text-align: left;
  }
}

#wholesale .kodawari .list article .text p {
  font-size: 1.5rem;
  text-align: left;
}

/************************************

#sec1 #partner

************************************/
#sec1 #partner {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #sec1 #partner {
    margin: 0 0 40px;
  }
}

#sec1 #partner .wrapper_s {
  max-width: 800px;
}

#sec1 #partner .list article {
  width: 46%;
  margin: 0 2% 30px;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #sec1 #partner .list article {
    width: 90%;
    margin: 0 auto 20px;
  }
}

#sec1 #partner .list article h3 {
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.7;
  margin: 0 0 10px;
}

#sec1 #partner .list article p {
  font-size: 1.5rem;
  text-align: left;
}

/************************************

#sec2 #flow

************************************/
#sec2 #flow {
  margin: 0 0 80px;
  padding: 80px 5%;
  background-color: #f8f8f6;
}

@media only screen and (max-width: 960px) {
  #sec2 #flow {
    margin: 0 0 40px;
    padding: 40px 5%;
  }
}

#sec2 #flow .chart {
  text-align: left;
  max-width: 860px;
  margin: 0 auto;
}

#sec2 #flow dl {
  margin: 0 0 15px;
}

#sec2 #flow dl::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 480px) {
  #sec2 #flow dl {
    padding: 0 0 55px;
    background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/flow_yaji@2x.png);
    background-size: 10px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
}

#sec2 #flow dl dt {
  float: left;
  width: 35%;
  border: 1px solid #333333;
  background-color: #fff;
  padding: 10px;
  position: relative;
  margin: 0 0 47px;
}

@media only screen and (max-width: 480px) {
  #sec2 #flow dl dt {
    float: none;
    width: 100%;
    margin: 0 0;
  }
}

#sec2 #flow dl dt::before {
  position: absolute;
  bottom: -47px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 10px;
  height: 47px;
  background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/flow_yaji@2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
}

@media only screen and (max-width: 480px) {
  #sec2 #flow dl dt::before {
    display: none;
  }
}

#sec2 #flow dl dt::after {
  content: "";
  display: block;
  clear: both;
}

#sec2 #flow dl dt .no {
  background-color: #333333;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 35px;
  font-size: 20px;
  line-height: 1;
  padding: 7px 0;
  float: left;
}

@media only screen and (max-width: 960px) {
  #sec2 #flow dl dt .no {
    width: 24px;
    font-size: 16px;
    padding: 4px 0;
  }
}

#sec2 #flow dl dt h3 {
  margin-left: 45px;
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2;
}

@media only screen and (max-width: 960px) {
  #sec2 #flow dl dt h3 {
    line-height: 1.4;
    margin-left: 30px;
    font-size: 1.6rem;
  }
}

#sec2 #flow dl dd {
  float: right;
  width: 60%;
  padding: 10px 0 0;
}

@media only screen and (max-width: 960px) {
  #sec2 #flow dl dd {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  #sec2 #flow dl dd {
    float: none;
    width: 100%;
  }
}

#sec2 #flow dl dd .bt a {
  background-color: #333333;
  color: #fff;
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  padding: 10px 30px;
  border-radius: 2em;
  margin: 10px 0 0;
}

#sec2 #flow dl dd .bt a::before {
  display: none;
}

#sec2 #flow dl:last-child {
  margin: 0;
  padding: 0;
  background-image: none;
}

#sec2 #flow dl:last-child dt {
  margin: 0;
}

#sec2 #flow dl:last-child dt::before {
  display: none;
}

/************************************

#sec2 #case

************************************/
#sec2 #case {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #sec2 #case {
    margin: 0 0 40px;
  }
}

#sec2 #case .wrapper_s {
  max-width: 900px;
}

@media only screen and (max-width: 960px) {
  #sec2 #case .wrapper_s {
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  #sec2 #case .wrapper_s {
    max-width: 100%;
  }
}

#sec2 #case article {
  margin: 0 0 40px;
  text-align: left;
}

#sec2 #case article::after {
  content: "";
  display: block;
  clear: both;
}

#sec2 #case article:last-child {
  margin: 0;
}

#sec2 #case article .image {
  float: right;
  width: 32%;
}

@media only screen and (max-width: 960px) {
  #sec2 #case article .image {
    float: none;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px;
  }
}

#sec2 #case article .text {
  float: left;
  width: 63%;
}

@media only screen and (max-width: 960px) {
  #sec2 #case article .text {
    float: none;
    width: 100%;
  }
}

#sec2 #case article .no {
  font-size: 7rem;
  line-height: 1;
  color: #c4bdab;
  float: left;
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 60%;
  margin: 0 0 15px;
}

@media only screen and (max-width: 960px) {
  #sec2 #case article .no {
    font-size: 5rem;
    width: 100px;
    height: 66px;
  }
}

#sec2 #case article.rakuno .no {
  background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/oem_ill1@2x.jpg);
}

#sec2 #case article.cafe .no {
  background-image: url(/wp/wp-content/themes/itsuki02/wholesale/images/oem_ill2@2x.jpg);
}

#sec2 #case article h3 {
  margin-left: 180px;
  font-size: 2.4rem;
  padding: 15px 0 0;
}

@media only screen and (max-width: 960px) {
  #sec2 #case article h3 {
    margin-left: 110px;
    font-size: 2.1rem;
  }
}

#sec2 #case article dl {
  clear: both;
  margin: 0 0 20px;
}

#sec2 #case article dl::after {
  content: "";
  display: block;
  clear: both;
}

@media only screen and (max-width: 480px) {
  #sec2 #case article dl {
    margin: 0 3% 20px;
  }
}

#sec2 #case article dl dt {
  float: left;
  width: 120px;
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
}

@media only screen and (max-width: 480px) {
  #sec2 #case article dl dt {
    float: none;
    width: 100%;
  }
}

#sec2 #case article dl dd {
  margin-left: 120px;
  border-left: 1px solid #333333;
  padding: 0 0 0 20px;
}

@media only screen and (max-width: 960px) {
  #sec2 #case article dl dd {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  #sec2 #case article dl dd {
    margin: 10px 0 0;
    border: none;
    padding: 0;
  }
}

/************************************

#sec3 #kodawari2

************************************/
#sec3 #kodawari2 .list article h3 {
  text-align: center;
}

#sec3 #kodawari2 .bt {
  margin: 30px 0 0;
}

/**=================================

ここから業務用販売(_wholesale.scss)

=================================**/
/************************************

#mainimage

************************************/
#athome #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #athome #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #athome #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#athome .sec

************************************/
/************************************

#athome_info

************************************/
#athome_info {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #athome_info {
    margin: 0 0 40px;
  }
}

#athome_info .wrapper_s {
  max-width: 700px;
}

@media only screen and (max-width: 960px) {
  #athome_info .wrapper_s {
    max-width: 90%;
  }
}

#athome_info h2 {
  position: relative;
  display: inline-block;
  font-size: 2.7rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  margin: 30px 0 50px;
  padding: 20px 50px;
}

@media only screen and (max-width: 960px) {
  #athome_info h2 {
    font-size: 2.1rem;
    padding: 15px 30px;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 480px) {
  #athome_info h2 {
    font-size: 1.8rem;
    padding: 15px 0;
    display: block;
  }
}

#athome_info h2::before {
  position: absolute;
  top: 0;
  left: 0px;
  width: 50px;
  height: 50px;
  border-top: 1px solid #333333;
  border-left: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #athome_info h2::before {
    width: 30px;
    height: 30px;
  }
}

#athome_info h2::after {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid #333333;
  border-right: 1px solid #333333;
  content: "";
}

@media only screen and (max-width: 960px) {
  #athome_info h2::after {
    width: 30px;
    height: 30px;
  }
}

#athome_info p {
  margin: 0 0 50px;
  line-height: 2;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #athome_info p {
    font-size: 1.5rem;
    margin: 0 0 25px;
  }
}

/************************************

#item

************************************/
#athome h2.sen {
  font-size: 2.4rem;
  letter-spacing: 0.08em;
  display: inline-block;
  position: relative;
  margin: 0 auto 70px;
}

@media only screen and (max-width: 960px) {
  #athome h2.sen {
    font-size: 2.1rem;
    letter-spacing: 0.03em;
    margin: 0 auto 50px;
  }
}

#athome h2.sen::before {
  position: absolute;
  bottom: -10px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 100px;
  height: 1px;
  background-color: #333333;
  content: "";
}

#athome #item {
  margin: 0 0 80px;
  padding: 80px 3%;
  background-color: #f8f8f6;
}

@media only screen and (max-width: 960px) {
  #athome #item {
    margin: 0 0 40px;
    padding: 40px 3%;
  }
}

@media only screen and (max-width: 960px) {
  #athome #item .list {
    margin: 0 auto;
  }
}

#athome #item .list article {
  width: 21%;
  margin: 0 2%;
}

@media only screen and (max-width: 960px) {
  #athome #item .list article {
    width: 46%;
    margin: 0 2% 2%;
  }
}

@media only screen and (max-width: 480px) {
  #athome #item .list article {
    width: 90%;
    margin: 0 auto 30px;
  }
}

#athome #item .list article .image img {
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 960px) {
  #athome #item .list article .image img {
    max-width: 70%;
    margin: 0 auto;
  }
}

#athome #item .list article .text {
  position: relative;
  padding: 40px 0 0;
}

@media only screen and (max-width: 960px) {
  #athome #item .list article .text {
    padding: 30px 0 0;
  }
}

#athome #item .list article .text .no {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: #c4bdab;
  font-size: 7rem;
  line-height: 1;
}

@media only screen and (max-width: 960px) {
  #athome #item .list article .text .no {
    font-size: 5rem;
  }
}

#athome #item .list article .text h3 {
  font-family: "游ゴシック", YuGothic, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴ Pro W3", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.7;
  margin: 0 0 10px;
}

@media only screen and (max-width: 960px) {
  #athome #item .list article .text h3 {
    text-align: left;
  }
}

#athome #item .list article .text p {
  font-size: 1.5rem;
  text-align: left;
}

/************************************

#flow

************************************/
#athome #flow {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #athome #flow {
    margin: 0 0 40px;
  }
}

#athome #flow .list {
  margin: 0 auto;
  max-width: 1000px;
}

#athome #flow .list article {
  width: 29.3%;
  margin: 0 2% 30px;
}

@media only screen and (max-width: 960px) {
  #athome #flow .list article {
    width: 46%;
    margin: 0 2% 30px;
  }
}

@media only screen and (max-width: 480px) {
  #athome #flow .list article {
    width: 90%;
    margin: 0 auto 30px;
  }
}

#athome #flow .list article .image img {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
}

#athome #flow .list article .text {
  position: relative;
  padding: 15px 0 0;
  text-align: left;
}

@media only screen and (max-width: 960px) {
  #athome #flow .list article .text {
    padding: 10px 0 0;
  }
}

#athome #flow .list article .text .no {
  color: #c4bdab;
  font-size: 4.8rem;
  line-height: 1;
}

@media only screen and (max-width: 960px) {
  #athome #flow .list article .text .no {
    font-size: 3.6rem;
  }
}

#athome #flow .list article .text p {
  font-size: 1.5rem;
  margin: 10px 0 0;
}

/************************************

#hokan

************************************/
#athome #hokan {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #athome #hokan {
    margin: 0 0 40px;
  }
}

#athome #hokan .wrapper_s {
  margin: 0 auto;
  max-width: 1000px;
}

#athome #hokan .text {
  float: left;
  width: 55%;
  text-align: left;
  line-height: 2;
}

@media only screen and (max-width: 960px) {
  #athome #hokan .text {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 480px) {
  #athome #hokan .text {
    float: none;
    width: 90%;
    margin: 0 auto 20px;
  }
}

#athome #hokan .image {
  float: right;
  width: 40%;
}

@media only screen and (max-width: 480px) {
  #athome #hokan .image {
    float: none;
    width: 90%;
    margin: 0 auto;
  }
}

/**=================================

ここから会社概要(_company.scss)

=================================**/
/************************************

#mainimage

************************************/
#company #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #company #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #company #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/company/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#company_info

************************************/
#company_info {
  margin: 0 0 80px;
}

@media only screen and (max-width: 960px) {
  #company_info {
    margin: 0 0 40px;
  }
}

#company_info .wrapper_s {
  max-width: 700px;
}

#company_info dl {
  padding: 15px;
  text-align: left;
  line-height: 1.8;
  border-bottom: 1px solid #333333;
}

#company_info dl::after {
  content: "";
  display: block;
  clear: both;
}

#company_info dl dt {
  float: left;
  width: 25%;
}

#company_info dl dd {
  float: right;
  width: 70%;
}

#company #access iframe {
  width: 100%;
  height: 400px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media only screen and (max-width: 960px) {
  #company #access iframe {
    height: 280px;
  }
}

/**=================================

ここからお問い合わせ(_contact.scss)

=================================**/
/************************************

#mainimage

************************************/
#contact #mainimage_second {
  background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #contact #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage@2x.jpg");
  }
}

@media only screen and (max-width: 480px) {
  #contact #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/contact/images/mainimage-sp@2x.jpg");
  }
}

/************************************

#contat_form

************************************/
#contat_form {
  /* 確認画面用 */
  /*  thanks */
}

#contat_form p {
  margin: 0 0 50px;
}

@media only screen and (max-width: 960px) {
  #contat_form p {
    font-size: 1.4rem;
    margin: 0 0 30px;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form p {
    text-align: left;
  }
}

#contat_form .tabs {
  margin-top: 50px;
  background-color: #f8f8f6;
  width: 100%;
  margin: 0 auto;
  padding: 1% 0 0 1%;
}

#contat_form .tab_item {
  width: calc(96% / 4);
  background-color: #dcdcd2;
  font-size: 1.6rem;
  text-align: center;
  display: block;
  margin: 0 1% 1% 0;
  padding: 15px 0;
  float: left;
  transition: all 0.2s ease;
  position: relative;
}

@media only screen and (max-width: 960px) {
  #contat_form .tab_item {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form .tab_item {
    width: calc(98% / 2);
  }
}

#contat_form .tab_item::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Safari用 */
  transform: translateY(-50%);
  right: 10px;
  width: 15px;
  height: 15px;
  background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_black@2x.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  z-index: 5;
}

@media only screen and (max-width: 960px) {
  #contat_form .tab_item::before {
    width: 10px;
    height: 10px;
  }
}

#contat_form .tab_item:hover {
  opacity: 0.75;
}

@media only screen and (max-width: 960px) {
  #contat_form .tab_item:hover {
    opacity: 1;
  }
}

#contat_form input[name="tab_item"] {
  display: none;
}

#contat_form #recruit:checked ~ #recruit_content,
#contat_form #wholesale:checked ~ #wholesale_content,
#contat_form #media:checked ~ #media_content,
#contat_form #etc:checked ~ #etc_content {
  display: block;
}

#contat_form .tabs input:checked + .tab_item {
  background-color: #333333;
  color: #fff;
}

#contat_form .tabs input:checked + .tab_item::before {
  background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_white@2x.png");
}

#contat_form .tab_content {
  display: none;
  padding: 5%;
  clear: both;
  overflow: hidden;
}

#contat_form .tab_content h3 {
  font-size: 2.1rem;
}

@media only screen and (max-width: 960px) {
  #contat_form .tab_content h3 {
    font-size: 1.9rem;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form .tab_content h3 {
    margin: 20px 0 15px;
  }
}

#contat_form .tab_content.inputform h4 {
  display: none;
}

#contat_form .tab_content.inputform .form-field2 h4 {
  display: block;
}

#contat_form .contact-form {
  max-width: 700px;
  margin: 0 auto;
}

#contat_form .contact-form .form-field {
  position: relative;
  margin: 40px 0;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #333333;
  text-align: left;
}

#contat_form .contact-form .form-field::after {
  content: "";
  display: block;
  clear: both;
}

#contat_form .contact-form .form-field h4 {
  font-size: 1.6rem;
  line-height: 26px;
  color: #888;
  float: left;
  width: 25%;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form .form-field h4 {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form .contact-form .form-field h4 {
    float: none;
    width: 100%;
  }
}

#contat_form .contact-form .form-field.form-field2 {
  padding-bottom: 10px;
}

#contat_form .contact-form .input-text {
  display: block;
  width: 100%;
  height: 36px;
  border: none;
  background-color: #f8f8f6;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form .input-text {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

#contat_form .contact-form .input-text:focus {
  outline: none;
}

#contat_form .contact-form .input-text:focus + .label, #contat_form .contact-form .input-text.not-empty + .label {
  transform: translateY(-24px);
}

#contat_form .contact-form textarea.input-text {
  height: 100px;
  font-size: 1.6rem;
  line-height: 1.8;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form textarea.input-text {
    font-size: 1.4rem;
  }
}

#contat_form .contact-form textarea.input-text:focus {
  outline: none;
}

#contat_form .contact-form textarea.input-text:focus + .label, #contat_form .contact-form textarea.input-text.not-empty + .label {
  transform: translateY(-30px);
}

#contat_form .contact-form .label {
  position: absolute;
  left: 0px;
  bottom: 11px;
  font-size: 1.6rem;
  line-height: 26px;
  color: #888;
  cursor: text;
  transition: transform 0.2s ease-in-out;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form .label {
    font-size: 1.4rem;
  }
}

#contat_form .contact-form .label.textarea {
  top: 0px;
  bottom: auto;
}

#contat_form .contact-form .radio_label {
  margin: 0 20px 0 0;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form .radio_label {
    font-size: 1.4rem;
  }
}

#contat_form .contact-form .radio_label input[type="radio"],
#contat_form .contact-form .radio_label input[type="checkbox"] {
  margin: 0 5px 0 0;
}

#contat_form .contact-form .selectstyle {
  position: relative;
  display: inline-block;
  background: #fefefe;
  background: linear-gradient(to bottom, #ffffff 0%, #fff 60%, #eee 100%);
  border-radius: 3px;
  font-size: 1.6rem;
  overflow: hidden;
  z-index: 0;
  padding-right: 0px;
  vertical-align: middle;
}

@media only screen and (max-width: 960px) {
  #contat_form .contact-form .selectstyle {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form .contact-form .selectstyle {
    margin: 10px 0 0;
    max-width: 100%;
    overflow: hidden;
  }
}

#contat_form .contact-form .selectstyle:after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  width: 12px;
  height: 12px;
  background-image: url("/wp/wp-content/themes/itsuki02/images/yaji_down_black@2x.png");
  background-size: contain;
  content: "";
}

#contat_form .contact-form .selectstyle select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.4em 2em 0.4em 1.5em;
  color: #000;
  background: transparent;
  border-radius: 3px;
  font-size: 100%;
  outline: none;
}

#contat_form .contact-form .selectstyle select::-ms-expand {
  display: none;
}

#contat_form .contact-form .selectstyle select::-moz-focus-inner {
  border: 0;
}

#contat_form .contact-form .submit-btn {
  background-color: #333333;
  border: none;
  color: #fff;
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 1.6rem;
  padding: 12px 30px;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  #contat_form .contact-form .submit-btn {
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 350px;
  }
}

#contat_form .tab_content.confirm {
  display: block;
}

#contat_form .tab_content.confirm .form-field {
  padding-bottom: 10px;
}

#contat_form .tab_content.confirm h4 {
  display: block;
}

#contat_form .tab_content.confirm .text {
  margin-left: 27%;
}

@media only screen and (max-width: 960px) {
  #contat_form .tab_content.confirm .text {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 480px) {
  #contat_form .tab_content.confirm .text {
    margin: 0;
  }
}

#contat_form .tab_content.confirm .selectstyle {
  position: relative;
  display: inline-block;
  background: none;
  border-radius: 0;
  font-size: 1em;
  overflow: inherit;
  z-index: 0;
  padding-right: 0px;
}

#contat_form .tab_content.confirm .selectstyle:after {
  display: none;
}

#contat_form .thanks p {
  line-height: 2;
}

@media only screen and (max-width: 960px) {
  #contat_form .thanks {
    margin: 0 3%;
  }
}
