@charset "UTF-8";

// common.scssにimport
/**=================================

ここから業務用販売(_wholesale.scss)

=================================**/

/************************************

#mainimage

************************************/
#athome {
  #mainimage_second {
    background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage.jpg");
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage@2x.jpg");
    }
    @include media-sp {
      background-image: url("/wp/wp-content/themes/itsuki02/athome/images/mainimage-sp@2x.jpg");
    }
  }
}
/************************************

#athome .sec

************************************/
#athome .sec {
  @include media-tab {
  }
}
/************************************

#athome_info

************************************/
#athome_info {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    max-width: 700px;
    @include media-tab {
      max-width: 90%;
    }
  }
  h2 {
    position: relative;
    display: inline-block;
    font-size: 2.7rem;
    line-height: 1.5;
    letter-spacing: 0.1em;
    margin: 30px 0 50px;
    padding: 20px 50px;
    @include media-tab {
      font-size: 2.1rem;
      padding: 15px 30px;
      margin: 0 0 30px;
    }
    @include media-sp {
      font-size: 1.8rem;
      padding: 15px 0;
      display: block;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0px;
      width: 50px;
      height: 50px;
      border-top: 1px solid $main-color;
      border-left: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
    &::after {
      position: absolute;
      bottom: 0;
      right: 0px;
      width: 50px;
      height: 50px;
      border-bottom: 1px solid $main-color;
      border-right: 1px solid $main-color;
      content: "";
      @include media-tab {
        width: 30px;
        height: 30px;
      }
    }
  }
  p {
    margin: 0 0 50px;
    line-height: 2;
    text-align: left;
    @include media-tab {
      font-size: 1.5rem;
      margin: 0 0 25px;
    }
  }
}

/************************************

#item

************************************/
#athome h2.sen {
  font-size: 2.4rem;
  letter-spacing: 0.08em;
  display: inline-block;
  position: relative;
  margin: 0 auto 70px;
  @include media-tab {
    font-size: 2.1rem;
    letter-spacing: 0.03em;
    margin: 0 auto 50px;
  }
  &::before {
    position: absolute;
    bottom: -10px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 100px;
    height: 1px;
    background-color: $main-color;
    content: "";
  }
}
#athome #item {
  margin: 0 0 80px;
  padding: 80px 3%;
  background-color: $back-color1;
  @include media-tab {
    margin: 0 0 40px;
    padding: 40px 3%;
  }

  .list {
    @include media-tab {
      //max-width: 600px;
      margin: 0 auto;
    }
    article {
      width: 21%;
      margin: 0 2%;
      @include media-tab {
        width: 46%;
        margin: 0 2% 2%;
      }
      @include media-sp {
        width: 90%;
        margin: 0 auto 30px;
      }
      .image img {
        border-radius: 50%;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        @include media-tab {
          max-width: 70%;
          margin: 0 auto;
        }
      }
      .text {
        position: relative;
        padding: 40px 0 0;
        @include media-tab {
          padding: 30px 0 0;
        }
        .no {
          position: absolute;
          top: -30px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          color: #c4bdab;
          font-size: 7rem;
          line-height: 1;
          @include media-tab {
            font-size: 5rem;
          }
        }
        h3 {
          font-family: $font-family-go;
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 1.7;
          margin: 0 0 10px;
          @include media-tab {
            text-align: left;
          }
        }
        p {
          font-size: 1.5rem;
          text-align: left;
        }
      }
    }
  }
}
/************************************

#flow

************************************/
#athome #flow {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }

  .list {
    margin: 0 auto;
    max-width: 1000px;
    @include media-tab {
    }
    article {
      width: 29.3%;
      margin: 0 2% 30px;
      @include media-tab {
        width: 46%;
        margin: 0 2% 30px;
      }
      @include media-sp {
        width: 90%;
        margin: 0 auto 30px;
      }
      .image img {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
      }
      .text {
        position: relative;
        padding: 15px 0 0;
        text-align: left;
        @include media-tab {
          padding: 10px 0 0;
        }
        .no {
          color: #c4bdab;
          font-size: 4.8rem;
          line-height: 1;
          @include media-tab {
            font-size: 3.6rem;
          }
        }
        p {
          font-size: 1.5rem;
          margin: 10px 0 0;
        }
      }
    }
  }
}
/************************************

#hokan

************************************/
#athome #hokan {
  margin: 0 0 80px;
  @include media-tab {
    margin: 0 0 40px;
  }
  .wrapper_s {
    margin: 0 auto;
    max-width: 1000px;
  }
  .text {
    float: left;
    width: 55%;
    text-align: left;
    line-height: 2;
    @include media-tab {
      font-size: 1.5rem;
    }
    @include media-sp {
      float: none;
      width: 90%;
      margin: 0 auto 20px;
    }
  }
  .image {
    float: right;
    width: 40%;
    @include media-sp {
      float: none;
      width: 90%;
      margin: 0 auto;
    }
  }
}
