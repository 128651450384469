@charset "UTF-8";
/*! このcssはsass（sass/同名の.scssファイル）からコンパイルされています。修正時はこのcssを直接いじらないようにお願いします */

@import "_setting.scss";
html {
  //ルートのフォントサイズを10pxに設定しておく
  font-size: 62.5%;
}
body {
  font-family: $font-family-min;
  text-align: center;
  color: $main-color;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  //* ルートのフォントサイズを1.6em（16pxと同等のサイズ）に設定 */
  font-size: 1.6em;
  letter-spacing: $letterspace;
  line-height: 1.8;
  @include media-tab {
  }
}
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
img {
  vertical-align: bottom;
  width: 100%;
  max-width: 100%;
  height: auto;
  @include media-tab {
    border: 0;
    max-width: 100%;
    height: auto;
  }
}

a {
  color: $main-color;
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    opacity: 0.7;
  }
  img {
    border: none;
  }
}

i {
  padding: 0 5px;
}
ul {
  letter-spacing: -1em;
  li {
    letter-spacing: $letterspace;
    list-style: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
.pc {
  display: block;
  @include media-tab {
    display: none;
  }
}
.tablet {
  display: none;
  @include media-tab {
    display: block;
  }
  @include media-sp {
    display: none;
  }
}
.sp {
  display: none;
  @include media-tab {
    display: block;
  }
}
.ssp {
  display: none;
  @include media-sp {
    display: block;
  }
}
/*PC改行加える*/
.br-pc {
  display: inherit;
  @include media-tab {
    display: none;
  }
}
/*PC改行無効*/
.br_sp {
  display: none;
  @include media-tab {
    display: block;
  }
}
.br_ssp {
  display: none;
  @include media-sp {
    display: block;
  }
}
/************************************

wrapper

************************************/

.wrapper {
  max-width: 1600px;
  width: calc(100% - 80px);
  margin: 0 auto;
  //background-color: #ccc;
  @include clearfix;
  @include media-tab {
    width: 92%;
    margin: auto;
  }
}
.wrapper_s {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  @include clearfix;
  @include media-tab {
    width: 100%;
  }
}

/************************************

.list(flexbox)

************************************/
.list {
  display: block; //* before Android4.3 iOS6:Safari */
  display: -ms-flexbox; //*IE10*/
  display: -webkit-flex; //* Safari */
  display: flex;
  -webkit-flex-wrap: wrap; //* Safari */
  flex-wrap: wrap;
  @include clearfix;
  article {
    width: auto;
  }
}
/************************************

header

************************************/
header {
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 15px 0;
  height: 110px;
  background-color: #fff;
  @include clearfix;
  @include media-tab {
    height: auto;
    position: relative;
  }
  h1 {
    float: left;
    width: 160px;
    @include media-tab {
      float: none;
      width: 120px;
      margin: 0 auto;
    }
    a {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: none;
      background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
      display: block;
      height: 0;
      width: 100%;
      padding-top: 50%;
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
      }
    }
  }
  nav {
    margin: 0px 0 0 200px;
    @include media-tab {
      display: none;
    }
    ul {
      padding: 40px 0 0;
      display: block; //* before Android4.3 iOS6:Safari */
      display: -ms-flexbox; //*IE10*/
      display: -webkit-flex; //* Safari */
      display: flex;
      -webkit-flex-wrap: wrap; //* Safari */
      flex-wrap: wrap;
      justify-content: flex-end;
      @include clearfix;
      li {
        width: auto;
        margin: 0 14px;
        font-size: 1.5rem;
      }
    }
  }
}
header .submenu {
  opacity: 0;
  height: 0;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 11;
  transition: 0.7s;
  .submenu2 {
    padding: 0;
    display: none;
  }
}
header nav li:hover .submenu {
  opacity: 1;
  height: auto;
  padding: 20px 0 0;
  .submenu2 {
    display: block;
    background-color: #fff;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }
  ul {
    padding: 0;
  }
}
/************************************

#sidemenu

************************************/
#sidemenu {
  position: fixed;
  right: 0;
  top: 30%;
  width: 53px;
  z-index: 10;
  @include media-tab {
    right: auto;
    left: 10px;
    top: auto;
    bottom: 0;
    width: 250px;
    ul {
      display: -ms-flexbox; //*IE10*/
      display: -webkit-flex; //* Safari */
      display: flex;
      -webkit-flex-wrap: wrap; //* Safari */
      flex-wrap: wrap;
    }
  }
  li a {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    display: block;
    margin: 0 0 20px;
    @include media-tab {
      margin: 0;
    }
  }
  li.onlineshop a {
    background-image: url("/wp/wp-content/themes/itsuki02/images/side_online.png");
    width: 53px;
    height: 297px;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
      background-image: url("/wp/wp-content/themes/itsuki02/images/side_online@2x.png");
    }
    @include media-tab {
      background-image: url("/wp/wp-content/themes/itsuki02/images/bottom_online@2x.png");
      width: 200px;
      height: 40px;
    }
  }
  li.facebook a {
    margin: auto;
    @include media-tab {
      margin: 5px;
    }
  }
}
.facebook a {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  display: block;
  background-image: url("/wp/wp-content/themes/itsuki02/images/facebook.png");
  width: 30px;
  height: 30px;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
    background-image: url("/wp/wp-content/themes/itsuki02/images/facebook@2x.png");
  }
}
/************************************

footer

************************************/
footer {
  padding: 50px 0;
  @include clearfix;
  h2 {
    margin: 0 auto 50px;
    width: 160px;
    @include media-tab {
      width: 120px;
    }
    a {
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: none;
      background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo.png");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain;
      display: block;
      height: 0;
      width: 100%;
      padding-top: 50%;
      @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
      }
    }
  }
  ul {
    display: block; //* before Android4.3 iOS6:Safari */
    display: -ms-flexbox; //*IE10*/
    display: -webkit-flex; //* Safari */
    display: flex;
    -webkit-flex-wrap: wrap; //* Safari */
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
    text-align: left;
    li {
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      margin: 0 15px;
      @include media-tab {
        font-size: 1.4rem;
        margin: 0 5px;
      }
    }
  }
  .facebook a {
    margin: 40px auto;
    @include media-tab {
      display: none;
    }
  }
  .copyright {
    font-size: 1rem;
    letter-spacing: 0.5em;
    @include media-tab {
      margin: 30px 0 0;
    }
  }
}
/************************************

PageUP

************************************/
#pageup {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 0;
  right: 0;
  z-index: 10;
  font-size: 8px;
  transition: 0.5s;
  @include media-tab {
    bottom: 0px;
    right: 0px;
    font-size: 4px;
  }
  &.none {
    display: none;
  }
  a {
    width: 50px;
    display: block;
    text-decoration: none;
    line-height: 100%;
    color: #333;
  }
  i {
    font-size: 40px;
    line-height: 0.8;
    @include media-tab {
      font-size: 36px;
    }
  }
}

/************************************

drawer

************************************/
.drawer-nav {
  width: 27rem;
  color: $main-color;
}
.drawer--right .drawer-nav {
  right: -27rem;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 27rem;
}

.drawer-hamburger {
  width: 3rem;
  padding-top: 14px;
  padding-right: 10px;
  padding-bottom: 30px;
  padding-left: 10ox;
  background-color: transparent;
}
.drawer-menu {
  padding: 5%;
}
.drawer-menu h1 {
  margin: 30px auto;
  width: 120px;
  a {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    background-image: url("/wp/wp-content/themes/itsuki02/images/htad_logo@2x.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    display: block;
    height: 0;
    width: 100%;
    padding-top: 50%;
  }
}
.drawer-menu li {
  font-family: $font-family-min;
  margin: 0 0 20px;
  font-size: 1.5rem;
  letter-spacing: 0.08em;
  ul {
    margin: 20px 0 0;

    li {
      display: inline-block;
      padding: 0 4px 0 10px;
      margin: 0 0 15px;
      font-size: 1.2rem;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0px;
        content: "/";
      }
      &:first-child::before,
      &:nth-child(4)::before {
        content: "";
      }
    }
  }
}
/************************************

main

************************************/
main {
  display: block;
  padding-top: 110px;
  @include media-tab {
    padding: 0;
  }
}
main section {
  opacity: 0;
  transform: translate(0, 60px);
  -webkit-transform: translate(0, 60px);
  transition: 1s;
  &.mv07 {
    opacity: 1;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
  }
  .white,
  .beige {
    padding: 80px 0;
    @include media-tab {
      padding: 50px 0;
    }
  }
  .white {
    background-color: #fff;
  }
  .beige {
    background-color: $back-color1;
  }
}

//フェードイン表示
.fadein {
  opacity: 0;
  transition: 1s;
  &.mv_fadein {
    opacity: 1;
  }
}
//見出し
.eng_midashi {
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0 0 40px;
  @include media-tab {
    font-size: 1.2rem;
    margin: 0 0 25px;
  }
  .eng {
    font-size: 2.8rem;
    display: block;
    margin: 0 0 5px;
    @include media-tab {
      font-size: 2.4rem;
    }
  }
}
// bt
.bt a {
  border: 1px solid $main-color;
  display: inline-block;
  margin: 0 0px;
  font-size: 1.6rem;
  padding: 10px 90px 10px 70px;
  position: relative;
  transition: 0.3s;
  @include media-tab {
    font-size: 1.4rem;
  }
  @include media-sp {
    padding: 10px 0;
    display: block;
    text-align: center !important;
    margin: 0;
  }
  &:hover {
    color: #fff;
    background-color: $main-color;
    opacity: 1;
  }
  &::before {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Safari用 */
    transform: translateY(-50%);
    right: 20px;
    width: 27px;
    height: 16px;
    background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji@2x.png");
    background-size: contain;
    content: "";
    transition: 0.3s;
  }
  &:hover::before {
    right: 10px;
    opacity: 1;
    background-image: url("/wp/wp-content/themes/itsuki02/images/bt_yaji_o@2x.png");
  }
}

#mainimage_second {
  width: 100%;
  height: 0;
  padding-top: 30%;
  margin: 0 auto 80px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include media-tab {
    margin: 0 auto 40px;
  }
  @include media-sp {
    padding-top: 50%;
  }
}
@import "_top.scss";
@import "_about.scss";
@import "_shop.scss";
@import "_wholesale.scss";
@import "_athome.scss";
@import "_company.scss";
@import "_contact.scss";
